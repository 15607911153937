export const tweetData = [
    {
        author: {
            name: 'Bonk Earn',
            username: 'BonkEarn',
            image: 'https://pbs.twimg.com/profile_images/1677344899136454658/okp3qjAF_400x400.jpg',
        },
        tweet: `So basically you hold $BERN right, then bc you’re holding it, Bernzy sends you more based on how much it’s traded
  
      Incredible really
      
      Bernzy’s favorite Bonzi`,
        time: new Date(2023, 6, 8, 7, 26),
        permalink: 'https://twitter.com/BonkEarn/status/1677685647757254656?s=20', // optional
        fitInsideContainer: true,
    },
    {
        author: {
            name: 'FluxBeam',
            username: 'FluxBeamDEX',
            image: 'https://pbs.twimg.com/profile_images/1382083582752096262/xrx0PO8Z_400x400.jpg',
        },
        tweet: `🧵 Token2022 - Default Account State
  
      What is it, and how does it benefit you? 
      
      While at face value, it may seem one of the more simple extensions but enables a whole host of new workflow for token ownership.
      
      Let's dig in!`,
        time: new Date(2023, 6, 14),
        permalink: 'https://twitter.com/FluxBeamDEX/status/1679757445827010560?s=20', // optional
        fitInsideContainer: true,
    },
    {
        author: {
            name: 'Waiting',
            username: 'SchizoWaiting',
            image: 'https://pbs.twimg.com/profile_images/1656308801694310402/NsNJ9q2k_400x400.jpg',
        },
        tweet: `If you do 22 token play on SOL, no reason not to own $BERN 

        Y'all need to stop selling this stuff after week of sideways to protect your 50 dollars
        
        There's no signs of exhaustion on SOL, shit looks bottomed, just buy your onchain coins and leave them`,
        time: new Date(2023, 6, 10, 13, 43),
        permalink: 'https://twitter.com/SchizoWaiting/status/1678505369822175233?s=20', // optional
        fitInsideContainer: true,
    },
    {
        author: {
            name: '🚬☕️🚬',
            username: 'cafeydospitis',
            image: 'https://pbs.twimg.com/profile_images/1625533626019356673/qG_0TwyC_400x400.jpg',
        },
        tweetImages: [
            { src: 'https://pbs.twimg.com/media/F0xIEj8XwAEunCw?format=jpg' },
        ],
        tweet: `Feels like 
        @BonkEarn
         is ready to go parabolic`,
        time: new Date(2023, 6, 11, 8, 58),
        permalink: 'https://twitter.com/cafeydospitis/status/1678796009965494272?s=20', // optional
        fitInsideContainer: true,
    },
    {
        author: {
            name: 'DegenDoes.Sol',
            isVerified: true,
            username: 'DegendoesSol',
            image: 'https://pbs.twimg.com/profile_images/1677651516126003200/j8D7KoWk_400x400.jpg',
        },
        tweetImages: [
            { src: 'https://pbs.twimg.com/media/F0-T15mXsAIkX29?format=jpg' },
        ],
        tweet: `Imagine getting daily rewards just for holding your bag. @BonkEarn pays out a % of the daily volume to its holders - That’s Token22, That’s $BERN`,
        time: new Date(2023, 6, 13, 12, 5),
        permalink: 'https://twitter.com/DegendoesSol/status/1679723745181589504?s=20', // optional
        fitInsideContainer: true,
    },
    {
        author: {
            name: 'Bonk Earn',
            username: 'BonkEarn',
            image: 'https://pbs.twimg.com/profile_images/1677344899136454658/okp3qjAF_400x400.jpg',
        },
        tweet: `Thank you for spreading the good word @aeyakovenko

        Bernzy will continue fighting the good fight`,
        time: new Date(2023, 6, 11, 8, 14),
        tweetImages: [
            { src: 'https://pbs.twimg.com/media/F0w980SX0AAxkhG?format=jpg' },
        ],
        permalink: 'https://twitter.com/BonkEarn/status/1678784879545204737', // optional
        fitInsideContainer: true,
    },
];
