import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Avatar, useColorMode } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { Center } from "@chakra-ui/layout";

export default function Prices({ bonk, bern, sol }) {
  const { colorMode } = useColorMode();
  const scaleFactor = 0.1;
  const Price = ({
    icon,
    title,
    subtitle,
    percent,
    change,
    loss,
    color1,
    color2,
  }) => (
    <Box>
      <Flex alignItems="center" mb={5}>
        <Avatar name={subtitle} src={`/${icon}.jpg`} />
        <Box ml={2}>
          <Heading fontSize="md">{title}</Heading>
          <Text fontSize="xs" mt={1}>
            {subtitle}
          </Text>
        </Box>
        <Spacer mx={5} />
        <Box textAlign="right">
          <Text fontSize="16" fontWeight="semibold">
            {percent}
          </Text>
          <Text fontSize="12" textColor={loss ? "red" : "black"}>
            {change}% 24h
          </Text>
        </Box>
      </Flex>
    </Box>
  );

  return (
    <Box ml={6} mr={4}>
      <Flex mb={6} textColor="blackAlpha.700" fontSize="sm">
        <Text textColor={colorMode === "light" ? "black" : "white"}>Coins</Text>
        <Spacer mx={5} />
        <Text
          fontWeight="semibold"
          textColor={
            colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.700"
          }
        >
          Price
        </Text>
      </Flex>
      <Stack>
        <Price
          title="BERN"
          subtitle="BonkEarn"
          icon="bern"
          percent={Number(bern?.value).toFixed(5)}
          change={Number(bern?.priceChange24h).toFixed(2)}
          color1="#FFECE8"
          color2="#FE8F7B"
        />
        <Price
          title="BONK"
          subtitle="Bonk"
          icon="bonk"
          percent={Number(bonk?.value).toFixed(8)}
          change={Number(bonk?.priceChange24h).toFixed(3)}
          color1="#FCF6DD"
          color2="#EDCC48"
        />
        <Price
          title="SOL"
          subtitle="Solana"
          icon="sol"
          percent={Number(sol?.value).toFixed(5)}
          change={Number(sol?.priceChange24h).toFixed(3)}
          color1="#E0F4F8"
          color2="#43B8D5"
        />
      </Stack>
      <Text
        textColor={colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.700"}
        fontSize="sm"
      >
        Powered by&nbsp;
        <Avatar size="xs" name="Dan Abrahmov" src="/birdy.png" />
      </Text>
    </Box>
  );
}
