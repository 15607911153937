import { Text } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { useColorMode } from "@chakra-ui/react";

export default function SmallCard({ value, text }) {
  const { colorMode } = useColorMode();
  return (
    <Box p={6} bg="#DBE3E5" borderRadius="3xl">
      <Heading
        fontWeight="light"
        fontSize="2xl"
        color={colorMode === "light" ? "black" : "black"}
      >
        {value}
      </Heading>
      <Text mt={4} color={colorMode === "light" ? "black" : "black"}>
        {text}
      </Text>
    </Box>
  );
}
