import { Image } from "@chakra-ui/image";
import { Spacer } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { FiTrendingUp, FiTwitter } from "react-icons/fi";
import { Heading } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import { useColorMode } from "@chakra-ui/react";

const Header = () => {
  const { toggleColorMode, colorMode } = useColorMode();

  const Item = ({ title }) => (
    <Flex p={2} borderWidth="1px" borderRadius="lg" alignItems="center">
      <Box textColor={colorMode === "light" ? "gray" : "white"}>
        <Heading size="sm" fontWeight="semibold" px="2">
          {title}
        </Heading>
      </Box>
      <Spacer />
      <Switch
        colorScheme="orange"
        onChange={toggleColorMode}
        isChecked={colorMode === "light" ? false : true}
      />
    </Flex>
  );
  return (
    <Box pt={5} px={8}>
      <Flex>
        <Image src="/assets/logo.png" w={12} />
        <Spacer />
        <HStack fontSize="xl" spacing="4">
          <Item title={` ${colorMode === "light" ? "Dark" : "Light"} Mode`} />

          <a href="https://birdeye.so/token/CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo?chain=solana">
            <FiTrendingUp />
          </a>
          <a
            href="https://twitter.com/BonkEarn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiTwitter />
          </a>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Header;
