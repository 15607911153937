import { useState, useEffect } from "react";
import TweetCard from "react-tweet-card";
import { tweetData } from "./components/Content/tweetData";
import {
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useColorMode,
} from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import BigCard from "./components/Content/BigCard";
import SmallCard from "./components/Content/SmallCard";
import Header from "./components/Header";
import Prices from "./components/Content/Prices";
import Holders from "./components/Content/Holders";
import Calculator from "./components/Content/Calculator";
import RewardCompounder from "./components/Content/RewardCompounder";
import Marquee from "react-fast-marquee";

const birdEyeApiKey = '770da6127cd0451881d3e154788bc68b';

function App() {
  const { colorMode } = useColorMode();
  const scaleFactor = 0.5;
  const [statsData, setStatsData] = useState(null);
  const [overview, setOverview] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://bern-api.vercel.app/api/stats");
        const responseData = await response.json();

        if (responseData && responseData.bonkBurned) {
          responseData.bonkBurned = responseData.bonkBurned / 100000;
        }

        if (responseData && responseData.bernBurned) {
          responseData.bernBurned = responseData.bernBurned / 100000;
        }

        if (responseData && responseData.rewardsAirdropped) {
          responseData.rewardsAirdropped =
            responseData.rewardsAirdropped / 100000;
        }

        if (responseData && responseData.feesClaimed) {
          responseData.feesClaimed = responseData.feesClaimed / 100000;
        }

        setStatsData(responseData);

        const response2 = await fetch(
          "https://bern-api.vercel.app/api/overview"
        );
        const responseData2 = await response2.json();

        if (responseData2 && responseData2.data) {
          setOverview(responseData2.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box>
      <Header />
      <Center mb="-30">
        <Image
          src={colorMode === "light" ? "/insights.png" : "/insights-dark.png"}
          style={{ transform: `scale(${scaleFactor})` }}
        />
      </Center>
      <Marquee>
        {/* <Text textColor="blackAlpha.900" fontSize="xl" fontWeight="bold" pb="20" pt="5">
          🔥&nbsp;BERN burn coming soon&nbsp;🔥
        </Text> */}
        {/* <Text textColor="blackAlpha.900" fontSize="xl" fontWeight="bold" pb="20" pt="5" ml="5" mr="5">
        &nbsp;|&nbsp;
        </Text> */}
        <Text
          textColor={
            colorMode === "light" ? "blackAlpha.900" : "whiteAlpha.900"
          }
          fontSize="xl"
          fontWeight="bold"
          pb="20"
          pt="5"
        >
          4.2% fees today, 1% used to burn BONK, 1.75% used to burn BERN, 1% goes
          back to holders
        </Text>
      </Marquee>
      <Center>
        <SimpleGrid columns={{ base: 1, md: 10 }} gap={3}>
          <GridItem colSpan={{ base: 1, md: 4 }}>
            <Stack spacing={8}>
              <BigCardWithData />
              <SmallCard
                value={statsData?.rewardsAirdropped?.toLocaleString() ?? 0}
                text="Reward Distribution 24h"
              />
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Stack spacing={4}>
              <SmallCard
                value={statsData?.bonkBurned?.toLocaleString() ?? 0}
                text="BONK Burned 24h"
              />
              <SmallCard
                value={statsData?.bernBurned?.toLocaleString() ?? 0}
                text="BERN Burned 24h"
              />
              <BernHoldersSmallCard />
              <SmallCard
                value={statsData?.feesClaimed?.toLocaleString() ?? 0}
                text="Fees Claimed 24h"
              />
              <SmallCard
                value={overview?.v24h?.toLocaleString() ?? 0}
                text="BERN Volume 24h"
              />
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <PricesWithData />
            <HoldersWithData />
          </GridItem>
        </SimpleGrid>
      </Center>
      <SimpleGrid columns={{ base: 1, md: 10 }} gap={5} mt={20} mx={12}>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <Text fontWeight="semibold">What the heck is Token 2022?</Text>
          <Text>
            It's a groundbreaking, earth shattering token program for Solana
            since the old one does not accomodate cool use cases, adoption,
            scaling. You can learn more about it using Solana's official{" "}
            <a href="https://spl.solana.com/token-2022" textColor="blue">
              docs here
            </a>
            .
          </Text>
          <Text mt={5} fontWeight="semibold">
            Is BERN the first token2022 coin?
          </Text>
          <Text>Hell yes.</Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4 }}>
          <Calculator />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 4 }}>
          <RewardCompounder />
        </GridItem>
      </SimpleGrid>
      <SimpleGrid m={12} columns={[1, null, 3]} pb={5} spacing="40px">
        {tweetData.map((tweet, index) => (
          <Box height="300px" key={index}>
            <TweetCard {...tweet} theme="dark" />
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}

function BigCardWithData() {
  const [bernData, setBernData] = useState(null);
  const [bonkData, setBonkData] = useState(null);
  const [totalBonk, setTotalBonk] = useState(0);
  const [totalBern, setTotalBern] = useState(0);
  const [bernBurn, setBernBurn] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://public-api.birdeye.so/defi/price?address=CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
          {
            headers: {
              'X-API-KEY': birdEyeApiKey,
            }
          }
        );
        const responseData1 = await response1.json();
        setBernData(responseData1.data);

        const response2 = await fetch(
          "https://public-api.birdeye.so/defi/price?address=DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
          {
            headers: {
              'X-API-KEY': birdEyeApiKey,
            }
          }
        );
        const responseData2 = await response2.json();
        setBonkData(responseData2.data);

        const response3 = await fetch("https://bern-api.vercel.app/api/totals");
        const responseData3 = await response3.json();

        if (responseData3 && responseData3.bonkBurned) {
          responseData3.bonkBurned = responseData3.bonkBurned / 100000;
        }

        if (responseData3 && responseData3.rewardsAirdropped) {
          responseData3.rewardsAirdropped =
            responseData3.rewardsAirdropped / 100000;
        }

        if (responseData3 && responseData3.bernBurned) {
          responseData3.bernBurned = responseData3.bernBurned / 100000;
        }

        setTotalBonk(responseData3.bonkBurned);
        setTotalBern(responseData3.rewardsAirdropped);
        setBernBurn(responseData3.bernBurned);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <BigCard
      bernPrice={bernData?.value}
      bonkPrice={bonkData?.value}
      totalBern={totalBern}
      totalBonk={totalBonk}
      bernBurn={bernBurn}
    />
  );
}

function PricesWithData() {
  const [bernData, setBernData] = useState(null);
  const [bonkData, setBonkData] = useState(null);
  const [solData, setSolData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://public-api.birdeye.so/defi/multi_price?list_address=CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
          {
            headers: {
              'X-API-KEY': birdEyeApiKey,
            }
          }
        );
        const responseData1 = await response1.json();
        setBernData(Object.values(responseData1.data)[0]);

        const response2 = await fetch(
          "https://public-api.birdeye.so/defi/multi_price?list_address=DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
          {
            headers: {
              'X-API-KEY': birdEyeApiKey,
            }
          }
        );
        const responseData2 = await response2.json();
        setBonkData(Object.values(responseData2.data)[0]);

        const response3 = await fetch(
          "https://public-api.birdeye.so/defi/multi_price?list_address=So11111111111111111111111111111111111111112",
          {
            headers: {
              'X-API-KEY': birdEyeApiKey,
            }
          }
        );
        const responseData3 = await response3.json();
        setSolData(Object.values(responseData3.data)[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return <Prices bern={bernData} bonk={bonkData} sol={solData} />;
}

function HoldersWithData() {
  const [holderData, setHolderData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(
          "https://stats.fluxbeam.xyz/tokens/CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo/balances?order=amount&page=0&limit=5"
        );
        const responseData1 = await response1.json();
        setHolderData(responseData1);

        console.log(responseData1);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return <Holders holders={holderData} />;
}

function BernHoldersSmallCard() {
  const [totalBernHolders, setTotalBernHolders] = useState(null);

  useEffect(() => {
    const fetchTotalBernHolders = async () => {
      try {
        const response = await fetch(
          "https://api.fluxbeam.xyz/v1/tokens/CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo/holders/count"
        );
        const data = await response.json();
        setTotalBernHolders(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTotalBernHolders();
  }, []);

  return (
    <SmallCard
      value={totalBernHolders?.toLocaleString() ?? 0}
      text="Total BERN Holders"
    />
  );
}

export default App;
