import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

export default ({ holders }) => {
    const MAX_SUPPLY = 999989262.39;

    const formatNumber = (num, precision = 2) => {
        const map = [
          { suffix: 'T', threshold: 1e12 },
          { suffix: 'B', threshold: 1e9 },
          { suffix: 'M', threshold: 1e6 },
          { suffix: 'K', threshold: 1e3 },
          { suffix: '', threshold: 1 },
        ];
      
        const found = map.find((x) => Math.abs(num) >= x.threshold);
        if (found) {
          const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
          return formatted;
        }
      
        return num;
      }

  const Item = ({ wallet, percent, amount, index }) => (
    <Box>
      <Flex>
        <Box
          fontSize="lg"
          borderRadius="lg"
          py={1}
          px={3}
          bg={!index ? "#FFF3F1" : "#F1F1F3"}
          textColor={!index ? "#FAB2A3" : "#9891A7"}
        >
           { index ?? 0 }
        </Box>
        <Box ml={2}>
          <Heading fontSize="sm" fontWeight="semibold">
            {wallet}
          </Heading>
          <Text fontSize="xs" fontWeight="thin">
            {amount} BERN
          </Text>
        </Box>
        <Spacer />
        <Text
          fontSize="sm"
          fontWeight="semibold"
        //   textColor={!index ? "#FE8872" : "#9F9AAB"}
        >
          {percent.toFixed(3)}%
        </Text>
      </Flex>
    </Box>
  );

  return (
    <Box mt={8} ml={6} mr={4}>
      <Flex fontSize="sm" textColor="gray.400">
        <Text>Top Holders</Text>
        <Spacer />
        <Text
          fontWeight="semibold"
          textColor="blackAlpha.700"
        >
          %
        </Text>
      </Flex>
      <Stack mt={4} spacing={4}>
      {/* <Holder title={(i + 1) + '. ' + h.owner.substr(0, 5) + '...' + h.owner.slice(-5)} percent={((h.amount / 100000) / MAX_SUPPLY) * 100} /> */}

      {holders && holders.map((holder, index) => {
          return <Item index={index + 1} wallet={holder.owner.substr(0, 5) + '...' + holder.owner.slice(-5)} amount={formatNumber(holder.amount / 100000)} percent={((holder.amount / 100000) / MAX_SUPPLY) * 100} />;
      })}
        {/* <Item title="Sent BTC" date="08 Jun, 2021" amount="-$1,289.00" isSent />
        <Item title="Received USDT" date="07 Jun, 2021" amount="+$1,289.00" />
        <Item title="Sent XRP" date="01 Jun, 2021" amount="-$2,889.00" isSent /> */}
      </Stack>
    </Box>
  );
};