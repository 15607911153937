import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import { Input, useColorMode } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { useState, useEffect } from "react";
import { Center } from "@chakra-ui/layout";

export default () => {
    const { colorMode } = useColorMode();

    const [wallet, setWallet] = useState('');
    const [earned, setEarned] = useState(null);
    const [searchDisabled, setSearchDisabled] = useState(false);

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            fetchEarned();
        }
    }

    const fetchEarned = async() => {
        if (wallet && wallet.length >= 32 && wallet.length <= 44) {
            setSearchDisabled(true);

            try {
            const response = await fetch(`https://bern-api.vercel.app/api/earn/${wallet}`);
            const responseData = await response.json();
            
            if (responseData.total) {
                setEarned(responseData.total);
            }  else {
                setEarned(0);
            }
            } catch (e) {
    
            } finally {
                setSearchDisabled(false);
            }
        }
    }

    return (
        <Box w="80%" float="right" mr={4}>
            <Flex fontWeight="semibold" fontSize="sm" textColor="gray.400">
                <Text>BERN Earned</Text>
                <Spacer />
            </Flex>

            <Stack mt={4}>
                <Input placeholder='Wallet Address' value={wallet} onKeyPress={onKeyPress} onChange={(e) => setWallet(e.target.value)} />
                <Button disabled={searchDisabled} colorScheme='yellow' onClick={fetchEarned}>Check</Button>
            </Stack>

            {earned !== null && <Center>
                <Text
                fontWeight="semibold"
                textColor={
                    colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.700"
                }
                _hover={{ cursor: "pointer" }}
                mt={4}
                >
                This wallet earned {earned.toFixed(3)} BERN
                </Text>
            </Center>}
        </Box>
    );
};