import { Heading } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";

export default ({ bernPrice, bonkPrice, totalBern, totalBonk, bernBurn }) => {
  const trimmedBernPrice = Number(bernPrice ?? 0).toFixed(3);
  let totalBurnedBonk = (bonkPrice * totalBonk) ?? 0;
  let totalAirdropped = (bernPrice * totalBern) ?? 0;
  let totalBurnedBern = (bernPrice * bernBurn) ?? 0;

  return (
    <Box p={8} borderRadius="3xl" bg="#5D4E7B" textColor="white">
      <Text textTransform="uppercase" fontSize="xs" fontWeight="bold">
        LIFETIME BONK BURNED
      </Text>
      <Heading mt={2}>$ {!isNaN(totalBurnedBonk) ? totalBurnedBonk.toLocaleString() : 0}</Heading>
      <Text fontSize="sm" textColor="gray.200" mt={1}>
        {totalBonk?.toLocaleString() ?? 0} $BONK
      </Text>
      <Text mt={8} textTransform="uppercase" fontSize="xs" fontWeight="bold">
        LIFETIME BERN DISTRIBUTED
      </Text>
      <Heading mt={2}>$ {!isNaN(totalAirdropped) ? totalAirdropped.toLocaleString() : 0}</Heading>
      <Text fontSize="sm" textColor="gray.200" mt={1}>
        {totalBern?.toLocaleString() ?? 0}
      </Text>

      <Text mt={8} textTransform="uppercase" fontSize="xs" fontWeight="bold">
        LIFETIME BERN BURNED
      </Text>
      <Heading mt={2}>$ {!isNaN(totalBurnedBern) ? totalBurnedBern.toLocaleString() : 0}</Heading>
      <Text fontSize="sm" textColor="gray.200" mt={1}>
        {bernBurn?.toLocaleString() ?? 0}
      </Text>
      {/* <HStack mt={4}>
        <Box w="50%">
          <Heading size="sms">0.0006</Heading>
          <Text fontSize="xs">Price of $BONK</Text>
        </Box>
        <Box w="50%">
          <Heading size="sms">{trimmedBernPrice}</Heading>
          <Text fontSize="xs">Price of $BERN</Text>
        </Box>
      </HStack> */}
    </Box>
  );
};
