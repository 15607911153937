import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import { Input, useColorMode } from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Center } from "@chakra-ui/layout";

export default function RewardCompounder() {
  const { colorMode } = useColorMode();
  const [sliderValue, setSliderValue] = useState(50);
  const [bernAmount, setBernAmount] = useState(694200);
  const [compoundTotal, setCompoundTotal] = useState(0);

  useEffect(() => {
    updateCompounded();
  }, []);

  useEffect(() => {
    if (!isNaN(bernAmount)) {
      updateCompounded();
    }
  }, [bernAmount]);

  const handleSlideChange = (val) => {
    setSliderValue(val);
    updateCompounded();
  }

  const updateCompounded = async() => {
      try {
        const response = await fetch(`https://bern-api.vercel.app/api/compound?numDays=${sliderValue}&amount=${bernAmount}`);
        const responseData = await response.json();

        if (responseData.earned) {
          setCompoundTotal(responseData.earned);
        }

      } catch (e) {

      }
  }

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  const Item = ({ title, subtitle, enabled }) => (
    <Flex p={4} borderWidth="1px" borderRadius="lg" alignItems="center">
      <Box textColor="gray">
        <Heading size="sm" fontWeight="semibold">
          {title}
        </Heading>
        <Text fontSize="xs">{subtitle}</Text>
      </Box>
      <Spacer />
      <Switch colorScheme="orange" defaultChecked={enabled} />
    </Flex>
  );

  return (
    <Box w="80%" float="right" mr={4}>
      <Flex fontWeight="semibold" fontSize="sm" textColor="gray.400">
        <Text>Reward Compounder</Text>
        <Spacer />
      </Flex>
      <Stack mt={4}>
        <Input placeholder="Amount of BERN" value={bernAmount} onChange={(e) => setBernAmount(e.target.value)} />
        <Slider
          aria-label="slider-ex-6"
          onChange={(val) => handleSlideChange(val)}
        >
          <SliderMark value={25} {...labelStyles}>
            15 days
          </SliderMark>
          <SliderMark value={50} {...labelStyles}>
            30 days
          </SliderMark>
          <SliderMark value={75} {...labelStyles}>
            60 days
          </SliderMark>
          <SliderMark value={75} {...labelStyles}>
            60 days
          </SliderMark>
          <SliderMark
            value={sliderValue}
            textAlign="center"
            bg="yellow.500"
            color="white"
            mt="-10"
            ml="-5"
            px="1"
            w="20"
          >
            {sliderValue} days
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg="yellow.300" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Stack>
      <Center>
        <Text
          fontWeight="semibold"
          textColor={
            colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.700"
          }
          _hover={{ cursor: "pointer" }}
          mt={10}
        >
          You would have earned {(compoundTotal).toFixed(3)} in $BERN
        </Text>
      </Center>
    </Box>
  );
}
